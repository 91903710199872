@import '../../style/custonVariable';
.container {
    padding: 15px;
    .borderColumn {
        border: 1px solid #ddd;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 2px;
       
    }
    .timeline {
        .demo_card_wrapper {
            @media (min-width : 1000px) {
                flex-flow: column wrap;
                width: 717px;
                margin: 0 auto;
            }
            position: relative;
            margin: auto;
            .demo_card {
                @media (min-width : 1000px) {
                    &:nth-child(odd){
                        margin-right: 45px;
                        .head{
                            background-color: #46b8e9;
                            &::before{
                                left: 354.5px;
                                position: absolute;
                                content: "";
                                width: 9px;
                                height: 9px;
                                background-color: #bdbdbd;
                                border-radius: 9px;
                                box-shadow: 0 0 2px 8px #f7f7f7;
                            }
                            &::after{
                                border-left-width: 15px;
                                border-left-style: solid;
                                left: 100%;
                                position: absolute;
                                content: "";
                                width: 0;
                                height: 0;
                                border-top: 15px solid transparent;
                                border-bottom: 15px solid transparent;
                            }
                        }
                    }
                    max-width: 330px;
                    margin: 0;
                    margin-top: 45px;
                    margin-bottom: 45px;
                }
                // box-shadow: 0 1px 22px 4px rgb(0 0 0 / 7%);
                position: relative;
                display: block;
                z-index: 2;
                background-color: $color-white;
                &:nth-child(1){
                    order: 1;
                }
                &:nth-child(even){
                    margin-left: 55%;
                    background-color: #7166ba;
                    .head{
                        // background-color: #46b8e9;
                        &::before{
                            right: 354.5px;
                            position: absolute;
                            content: "";
                            width: 9px;
                            height: 9px;
                            background-color: #bdbdbd;
                            border-radius: 9px;
                            box-shadow: 0 0 2px 8px #f7f7f7;
                        }
                        &::after{
                            border-right-width: 15px;
                            border-right-style: solid;
                            right: 100%;
                            position: absolute;
                            content: "";
                            width: 0;
                            height: 0;
                            border-top: 15px solid transparent;
                            border-bottom: 15px solid transparent;
                             background-color: #7166ba;
                        }
                    }
                }
                .head{
                    position: relative;
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-weight: 400;
                    .number_box{
                        display: inline;
                        float: left;
                        margin: 15px;
                        padding: 10px;
                        font-size: 35px;
                        line-height: 35px;
                        font-weight: 600;
                        background: rgba(0,0,0,0.17);
                    }
                    &:after{
                        background-color: #46b8e9;

                    }
                }
                .body {
                    background: #fff;
                    border: 1px solid rgba(191,191,191,0.4);
                    border-top: 0;
                    padding: 15px;
                    text-align: center;
                }
            }
            
            &::after{
                border-left: 1px solid #bdbdbd;
                z-index: 1;
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
            }
            
            
        }
    }

    .msgContainerBase {
        margin: 0;
        padding: 0 10px 10px;
        max-height: 380px;
        overflow-x: hidden;
        .msgContainer {
            padding: 10px;
            overflow: hidden;
            display: grid;
            padding-right: 60px;
            grid-template-columns: 40px 1fr;
            grid-gap: 10px;
            margin-left: -11px;
            align-items: end;
            .avatar {
                position: relative;
                display: block;
                white-space: nowrap;
                border-radius: 500px;
                img {
                    width: 40px;
                    height: 40px;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 0;
                        height: 0;
                        border: 5px solid #FFF;
                        border-left-color: rgba(0,0,0,0);
                        border-bottom-color: rgba(0,0,0,0);
                    }
                }
            }
        }
        .messages {
            margin-left: 0;
            background: #d6d0ff;
            margin-top: 0;
            padding: 10px;
            border-radius: 4px;
            max-width: 100%;
            position: relative;

            p {
                font-size: 15px;
                margin: 0 0 .2rem;
                color: #333;
            }
            &:after {
                position: absolute;
                clip-path: polygon(100% 36%, 0 100%, 100% 100%);
                width: 10px;
                height: 20px;
                background: #d6d0ff;
                content: "";
                left: -7px;
                bottom: 0px;   
            }

            img {
                width: 35%;
            }
        }
    }

    .chatMsgAttach {
        padding-left: 58px;
        padding-top: 10px;
        padding-right: 80px;
        .chatMsg {
            display: grid;
            grid-template-columns: 1fr 50px;
            grid-gap: 10px;
            align-items: center;

            .formGroup {
                margin-bottom: 0;
                .formControl {
                    border-radius: 52px!important;
                    padding: 8px 20px 4px;
                    line-height: 1.5;
                    height: 65px;
                    
                }
            }   
            button {
                display: inline-block;
                margin-bottom: 0;
                white-space: nowrap;
                vertical-align: middle;
                touch-action: manipulation;
                cursor: pointer;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                background-image: none;
                border: 1px solid transparent;
                font-weight: 500;
                outline: 0!important;
                color: #fff!important;
                background-color: #07c196!important;
                border-color: #07c196;
                text-align: center;
                padding: 0 2px;
                width: 55px;
                height: 55px;
                font-size: 20px!important;
                line-height: 0;
                border-radius: 100%;
            }

        }

        .chatAttach {
            .fileuploadNew {
                margin-bottom: 9px;
                margin-top: 1em;
                .btnFile {
                    display: inline-block;
                    margin-bottom: 0;
                    line-height: 1.42857143;
                    text-align: center;
                    white-space: nowrap;
                    -ms-touch-action: manipulation;
                    touch-action: manipulation;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    border: 1px solid transparent;
                    font-weight: 500;
                    outline: 0!important;
                    overflow: hidden;
                    position: relative;
                    vertical-align: middle;
                    background: #eee;
                    padding: 5px 18px 8px;
                    font-size: 15px;
                    border-radius: 25px;
                    color: #443c71;

                        i {
                            font-size: 24px;
                            transform: rotate(-100deg);
                            color: #7266ba;
                            bottom: -2px;
                            position: relative;
                        }

                        .fileuploadExists  {
                            display: none;
                        }

                        input {
                            position: absolute;
                            top: 0;
                            right: 0;
                            margin: 0;
                            opacity: 0;
                            filter: alpha(opacity=0);
                            transform: translate(-300px, 0) scale(4);
                            font-size: 23px;
                            direction: ltr;
                            cursor: pointer;
                        }
                }
                .closeAttach {
                    text-decoration: none;
                    cursor: pointer;
                    word-break: break-all;
                    font-size: 15px;
                    font-weight: 700;
                    line-height: 1;
                    text-shadow: 0 1px 0 #fff;
                    opacity: 1.2;
                    color: #000;
                }
            }
        }


    }

    .tableChat {
        width: auto;
        color: #333;
        max-width: 100%;
        margin-bottom: 20px;
        td,th {
            padding: 7px 15px;
            border-top: 0 solid #eaeff0;
            line-height: 1.42857143;
            vertical-align: top;
        }
    }

    .alertFaq {
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
        padding-right: 35px;
        font-size: 13px;
        // box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%);
        color: #239822;
        background-color: #fcf8e3;
        border-color: #faebcc;
    }

    .accorionItem {
        border: 1px solid  #dee5e7 !important;
        margin-bottom: 20px;
        .cardHeader {
            padding: 10px 15px !important;
            .plusIcon {
                float: right !important;
                cursor: pointer;
                padding: 0;
            }
        }
    }

    .btnGrop{
        height: 34px;
        margin-bottom: 5px;
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
        margin: 2px;
        margin-bottom: 5px;
        &.primary{
            background-color: $color-primary;
            color: $color-white;
        }
        &.info{
            background-color: $color-info;
            color: $color-white;
            &:hover{
                background-color: #19a9d5;
            }
        }
        &.success{
            background-color: $color-success;
            color: $color-white;
        }
        i{
            width: 30px;
            height: 30px;
            margin: -6px -12px;
            margin-right: 10px;
            line-height: 30px;
            position: relative;
            float: left;
            width: 34px;
            height: 34px;
            margin: -7px -12px;
            margin-right: 12px;
            line-height: 34px;
            text-align: center;
            background-color: rgba(0,0,0,0.1);
            border-radius: 2px 0 0 2px;
        }
    }
}


.filterButtons{
    button{
        padding: 10px;
        margin-left: 5px;
        @media only screen and (max-width: 907px){
            margin-top: 20px;
        }
    }
}

.searchButton{
    background-color: $color-primary;
    border: none;
   
    &:hover{
        background-color: $color-primary;       
    }
    &:focus{
        background-color: $color-primary;        
    }
}

.searchButton:active{
    background-color: $color-primary !important; 
}

.submittButton{
    background-color: $color-primary;
    border: none;
   
    &:hover{
        background-color: $color-primary;       
    }
    &:focus{
        background-color: $color-primary;        
    }
}

.submittButton:active{
    background-color: $color-primary !important; 
}


.resetButton{
  background-color: $color-info;
    border: none;
    color: white;
    &:hover{
        background-color:  $color-info;
        color: white;       
    }
    &:focus{
        background-color:  $color-info;
        color: white;       
    }
}

.resetButton:active{
    background-color:  $color-info !important; 
    color: white !important;
}

.dark_mode_textArea {
    font-size: 15px;
    height: 70px !important;
    background-color: #253548 !important;
    color: white !important;
    border: none;
}

.fileuploadInfo{
    color:#000;
}

/****** dark mode ********/ 

.dark_mode_fileuploadInfo{
    color:#fff;
}

@import '../../style/custonVariable';
.container {
    padding: 15px;
    .borderColumn {
        border: 1px solid #ddd;
        padding: 15px;
        margin-bottom: 20px;
        border-radius: 2px;
       
    }
    .timeline {
        .demo_card_wrapper {
            @media (min-width : 1000px) {
                flex-flow: column wrap;
                width: 717px;
                margin: 0 auto;
            }
            position: relative;
            margin: auto;
            .demo_card {
                @media (min-width : 1000px) {
                    &:nth-child(odd){
                        margin-right: 45px;
                        .head{
                            background-color: #46b8e9;
                            &::before{
                                left: 354.5px;
                                position: absolute;
                                content: "";
                                width: 9px;
                                height: 9px;
                                background-color: #bdbdbd;
                                border-radius: 9px;
                                box-shadow: 0 0 2px 8px #f7f7f7;
                            }
                            &::after{
                                border-left-width: 15px;
                                border-left-style: solid;
                                left: 100%;
                                position: absolute;
                                content: "";
                                width: 0;
                                height: 0;
                                border-top: 15px solid transparent;
                                border-bottom: 15px solid transparent;
                            }
                        }
                    }
                    max-width: 330px;
                    margin: 0;
                    margin-top: 45px;
                    margin-bottom: 45px;
                }
                // box-shadow: 0 1px 22px 4px rgb(0 0 0 / 7%);
                position: relative;
                display: block;
                z-index: 2;
                background-color: $color-white;
                &:nth-child(1){
                    order: 1;
                }
                &:nth-child(even){
                    margin-left: 55%;
                    background-color: #7166ba;
                    .head{
                        // background-color: #46b8e9;
                        &::before{
                            right: 354.5px;
                            position: absolute;
                            content: "";
                            width: 9px;
                            height: 9px;
                            background-color: #bdbdbd;
                            border-radius: 9px;
                            box-shadow: 0 0 2px 8px #f7f7f7;
                        }
                        &::after{
                            border-right-width: 15px;
                            border-right-style: solid;
                            right: 100%;
                            position: absolute;
                            content: "";
                            width: 0;
                            height: 0;
                            border-top: 15px solid transparent;
                            border-bottom: 15px solid transparent;
                             background-color: #7166ba;
                        }
                    }
                }
                .head{
                    position: relative;
                    display: flex;
                    align-items: center;
                    color: #fff;
                    font-weight: 400;
                    .number_box{
                        display: inline;
                        float: left;
                        margin: 15px;
                        padding: 10px;
                        font-size: 35px;
                        line-height: 35px;
                        font-weight: 600;
                        background: rgba(0,0,0,0.17);
                    }
                    &:after{
                        background-color: #46b8e9;

                    }
                }
                .body {
                    background: #fff;
                    border: 1px solid rgba(191,191,191,0.4);
                    border-top: 0;
                    padding: 15px;
                    text-align: center;
                }
            }
            
            &::after{
                border-left: 1px solid #bdbdbd;
                z-index: 1;
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                left: 50%;
            }
            
            
        }
    }

    .msgContainerBase {
        margin: 0;
        padding: 0 10px 10px;
        max-height: 380px;
        overflow-x: hidden;
        .msgContainer {
            padding: 10px;
            overflow: hidden;
            display: grid;
            padding-right: 60px;
            grid-template-columns: 40px 1fr;
            grid-gap: 10px;
            margin-left: -11px;
            align-items: end;
            .avatar {
                position: relative;
                display: block;
                white-space: nowrap;
                border-radius: 500px;
                img {
                    width: 40px;
                    height: 40px;
                    &:after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 0;
                        height: 0;
                        border: 5px solid #FFF;
                        border-left-color: rgba(0,0,0,0);
                        border-bottom-color: rgba(0,0,0,0);
                    }
                }
            }
        }
        .messages {
            margin-left: 0;
            background: #d6d0ff;
            margin-top: 0;
            padding: 10px;
            border-radius: 4px;
            max-width: 100%;
            position: relative;

            p {
                font-size: 15px;
                margin: 0 0 .2rem;
                color: #333;
            }
            &:after {
                position: absolute;
                clip-path: polygon(100% 36%, 0 100%, 100% 100%);
                width: 10px;
                height: 20px;
                background: #d6d0ff;
                content: "";
                left: -7px;
                bottom: 0px;   
            }

            img {
                width: 35%;
            }
        }
    }

    .chatMsgAttach {
        padding-left: 58px;
        padding-top: 10px;
        padding-right: 80px;
        .chatMsg {
            display: grid;
            grid-template-columns: 1fr 50px;
            grid-gap: 10px;
            align-items: center;

            .formGroup {
                margin-bottom: 0;
                .formControl {
                    border-radius: 52px!important;
                    padding: 8px 20px 4px;
                    line-height: 1.5;
                    height: 65px;
                    
                }
            }   
            button {
                display: inline-block;
                margin-bottom: 0;
                white-space: nowrap;
                vertical-align: middle;
                touch-action: manipulation;
                cursor: pointer;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                background-image: none;
                border: 1px solid transparent;
                font-weight: 500;
                outline: 0!important;
                color: #fff!important;
                background-color: #07c196!important;
                border-color: #07c196;
                text-align: center;
                padding: 0 2px;
                width: 55px;
                height: 55px;
                font-size: 20px!important;
                line-height: 0;
                border-radius: 100%;
            }

        }

        .chatAttach {
            .fileuploadNew {
                margin-bottom: 9px;
                margin-top: 1em;
                .btnFile {
                    display: inline-block;
                    margin-bottom: 0;
                    line-height: 1.42857143;
                    text-align: center;
                    white-space: nowrap;
                    -ms-touch-action: manipulation;
                    touch-action: manipulation;
                    cursor: pointer;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    border: 1px solid transparent;
                    font-weight: 500;
                    outline: 0!important;
                    overflow: hidden;
                    position: relative;
                    vertical-align: middle;
                    background: #eee;
                    padding: 5px 18px 8px;
                    font-size: 15px;
                    border-radius: 25px;
                    color: #443c71;

                        i {
                            font-size: 24px;
                            transform: rotate(-100deg);
                            color: #7266ba;
                            bottom: -2px;
                            position: relative;
                        }

                        .fileuploadExists  {
                            display: none;
                        }

                        input {
                            position: absolute;
                            top: 0;
                            right: 0;
                            margin: 0;
                            opacity: 0;
                            filter: alpha(opacity=0);
                            transform: translate(-300px, 0) scale(4);
                            font-size: 23px;
                            direction: ltr;
                            cursor: pointer;
                        }
                }
                .closeAttach {
                    text-decoration: none;
                    cursor: pointer;
                    word-break: break-all;
                    font-size: 15px;
                    font-weight: 700;
                    line-height: 1;
                    text-shadow: 0 1px 0 #fff;
                    opacity: 1.2;
                    color: #000;
                }
            }
        }


    }

    .dark_mode_tableChat {
        width: auto;
        color: #fff;
        max-width: 100%;
        margin-bottom: 20px;
        td,th {
            padding: 7px 15px;
            border-top: 0 solid #eaeff0;
            line-height: 1.42857143;
            vertical-align: top;
        }
    }

    .alertFaq {
        padding: 15px;
        margin-bottom: 20px;
        border: 1px solid transparent;
        border-radius: 4px;
        padding-right: 35px;
        font-size: 13px;
        // box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%);
        color: #239822;
        background-color: #fcf8e3;
        border-color: #faebcc;
    }

    .accorionItem {
        border: 1px solid  #dee5e7 !important;
        margin-bottom: 20px;
        .cardHeader {
            padding: 10px 15px !important;
            .plusIcon {
                float: right !important;
                cursor: pointer;
                padding: 0;
            }
        }
    }

    .btnGrop{
        height: 34px;
        margin-bottom: 5px;
        display: inline-block;
        padding: 6px 12px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;
        border-radius: 4px;
        margin: 2px;
        margin-bottom: 5px;
        &.primary{
            background-color: $color-primary;
            color: $color-white;
        }
        &.info{
            background-color: $color-info;
            color: $color-white;
            &:hover{
                background-color: #19a9d5;
            }
        }
        &.success{
            background-color: $color-success;
            color: $color-white;
        }
        i{
            width: 30px;
            height: 30px;
            margin: -6px -12px;
            margin-right: 10px;
            line-height: 30px;
            position: relative;
            float: left;
            width: 34px;
            height: 34px;
            margin: -7px -12px;
            margin-right: 12px;
            line-height: 34px;
            text-align: center;
            background-color: rgba(0,0,0,0.1);
            border-radius: 2px 0 0 2px;
        }
    }
}


.filterButtons{
    button{
        padding: 10px;
        margin-left: 5px;
        @media only screen and (max-width: 907px){
            margin-top: 20px;
        }
    }
}

.searchButton{
    background-color: $color-primary;
    border: none;
   
    &:hover{
        background-color: $color-primary;       
    }
    &:focus{
        background-color: $color-primary;        
    }
}

.searchButton:active{
    background-color: $color-primary !important; 
}

.submittButton{
    background-color: $color-primary;
    border: none;
   
    &:hover{
        background-color: $color-primary;       
    }
    &:focus{
        background-color: $color-primary;        
    }
}

.submittButton:active{
    background-color: $color-primary !important; 
}


.resetButton{
  background-color: $color-info;
    border: none;
    color: white;
    &:hover{
        background-color:  $color-info;
        color: white;       
    }
    &:focus{
        background-color:  $color-info;
        color: white;       
    }
}

.resetButton:active{
    background-color:  $color-info !important; 
    color: white !important;
}

.dark_mode_textArea {
    font-size: 15px;
    height: 70px !important;
    background-color: #253548 !important;
    color: white !important;
    border: none;
}

/****** end of dark mode *****/ 