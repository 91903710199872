.folderHover{
    background: none;
    box-shadow: none;
    /* flex-direction: unset; */
}

.folderHover:hover{
      background: #e0f1f6;
    }

    /* .vimm iframe{
      display: block;
      width:'800px';
      height:'800px';
    } */